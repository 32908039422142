<template>
  <div class="profile-active-sessions" :class="[{'p-2': $route.name === 'profileActiveSessions'}]">
    <div v-if="activeSessions.length > allowedActiveSessions" class="max-sessions-helper">
      {{ $t('profile.activeSessions.labels.maxSessionHelper', {num: allowedActiveSessions}) }}
    </div>

    <div class="active-sessions-list">
      <template v-for="(session, key) in activeSessions">
        <div class="active-sessions-item" :key="key">
          <div class="device-icon-box">
            <custom-icon :icon="getDeviceIcon(session.device)" color-code="#000" height="25px" width="25px" />
          </div>
          <div class="active-sessions-info-box">
            <div class="session-box-header">
              <div class="session-device">
                {{ session.deviceName || session.os || '-' }}
              </div>
              <div dir="ltr" class="session-last-used" :class="{'text-primary': session.current}">
                <template v-if="session.current">
                  {{ $t('profile.activeSessions.labels.currentSession') }}
                </template>
                <template v-else>
                  {{ session.lastUsed }}
                </template>
              </div>
            </div>
            <div class="session-browser">
              {{ session.browser }}
            </div>
            <div class="session-ip">
              {{ session.ip }}
            </div>
<!--            <div class="session-sign-in">-->
<!--              {{ $t('profile.activeSessions.labels.signIn', { date: session.createdAt }) }}-->
<!--            </div>-->
          </div>
          <div class="revoke-btn-box">
            <button v-if="!session.current" class="revoke-btn" @click="handleRevokeActiveSession(session)">
              {{ $t('profile.activeSessions.labels.revoke') }}
            </button>
          </div>
        </div>
      </template>
    </div>


    <custom-dialog ref="revokeActiveSessionConfirmation"
                   :title="$t('profile.activeSessions.confirmations.revoke.title')"
                   :body="$t('profile.activeSessions.confirmations.revoke.body', {ip: selectedSession.ip || '-'})"
                   @accept="revokeActiveSession()"/>
  </div>
</template>

<script>
  import DeviceDetector from 'device-detector-js'
  import {getProfile, getProfileActiveSessions, revokeActiveSession} from "../../../../http/requests/profile";
  import {convertUtcToLocale} from "../../../../assets/js/functions";
  import CustomIcon from "../../../../components/customIcon/customIcon";
  import CustomDialog from "../../../../components/customDialog/customDialog";

  export default {
    name: "profileActiveSessions",
    components: {CustomDialog, CustomIcon},
    metaInfo () {
      return {
        title: this.$t('profile.activeSessions.title')
      }
    },
    props: {
      maxActiveSessions: {
        type: Number,
        default: 0
      }
    },
    data () {
      return {
        activeSessions: [],
        selectedSession: {},
        allowedActiveSessions: 0,
        actions: {
          leftToolbar: [
            {
              id: 'routeBackBTN',
              route: {name: 'Profile'},
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      }
    },
    created () {
      this.$nextTick(() => {
        this.$store.dispatch('updateNavbarActions', this.actions)
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'mx-auto')
      })
      this.$store.dispatch('setPageTitle', this.$t('profile.activeSessions.title'))
      if (this.$route.name === 'profileActiveSessions') {
        this.getProfile()
      } else {
        this.allowedActiveSessions = this.maxActiveSessions
      }
      this.getActiveSessions()
    },
    methods: {
      getActiveSessions () {
        getProfileActiveSessions().then((response) => {
          const activeSessions = response.data.data
          this.activeSessions = []
          activeSessions.forEach(session => {
            const deviceDetector = new DeviceDetector()
            const userAgent = deviceDetector.parse(session.user_agent)
            this.activeSessions.push({
              id: session.id,
              ip: session.ip || '',
              name: session.name || '',
              createdAt: convertUtcToLocale(session.created_at, this.$localeValidator('moment.dateTime')),
              lastUsed: convertUtcToLocale(session.last_used_at, this.$localeValidator('moment.dateTime')),
              browser: userAgent.client ? userAgent.client.name : '',
              os: userAgent.os ? `${userAgent.os.name} ${userAgent.os.version}` : '',
              deviceName: userAgent.device && userAgent.device.brand ? `${userAgent.device.brand} ${userAgent.device.model}` : '',
              // device type can be ['smartphone', 'tablet', 'television', 'desktop']
              device: userAgent.device ? userAgent.device.type : '-',
              current: session.current
            })
          })
        })
      },
      getDeviceIcon (device) {
        const mobileArray = ['smartphone', 'tablet']
        const desktopArray = ['desktop']
        if (mobileArray.indexOf(device) > -1) {
          return 'MOBILE'
        } else if (desktopArray.indexOf(device) > -1) {
          return 'LAPTOP'
        } else {
          return 'CLOCK'
        }
      },
      handleRevokeActiveSession (session) {
        this.selectedSession = session
        this.$refs.revokeActiveSessionConfirmation.showDialog()
      },

      revokeActiveSession () {
        revokeActiveSession(this.selectedSession.id).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('profile.notifications.revokeSession.success'),
            color: 'success',
            time: 2400,
            icon: 'icon-check',
            iconPack: 'feather'
          })

          const selectedSessionIndex = this.activeSessions.map(elm => elm.id).indexOf(this.selectedSession.id)
          this.activeSessions.splice(selectedSessionIndex, 1)
          this.$emit('changeActiveSession', this.activeSessions.length)
          this.$store.dispatch('auth/updateUserInfo', {active_sessions: this.activeSessions.length})
          // this.$parent.$parent.$parent.$data.user.activeSessions = this.activeSessions.length
        }).catch(() => {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('profile.notifications.revokeSession.error'),
            color: 'danger',
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        })
      },
      getProfile () {
        getProfile().then((response) => {
          const profile = response.data.data
          this.allowedActiveSessions = profile.role.allowed_active_sessions
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../assets/scss/vuexy/variables";

  .profile-active-sessions {
    height: 100%;
    background-color: #fff;

    .max-sessions-helper {
      padding: 0 10px;
      text-align: center;
      color: $danger;
    }

    .active-sessions-list {
      padding: 10px;
      overflow: hidden;

      .active-sessions-item {
        display: flex;
        align-items: flex-start;
        padding: 10px 0;
        border-bottom: 1px solid #cdcdcd;

        &:last-child {
          border-bottom: 0;
        }

        .device-icon-box {
          width: 40px;
        }

        .active-sessions-info-box {
          flex-grow: 1;
          /*flex-basis: auto;*/
          /*flex-shrink: 1;*/
          transition: all ease 0.4s;

          .session-box-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .session-device {
              font-weight: bold;
              font-size: 17px;
            }

            .session-last-used {
              font-weight: bold;
              color: #bdbdbd;
            }
          }

          .session-ip {
            font-weight: bold;
            color: #bdbdbd;
          }
        }

        .revoke-btn-box {
          width: 0;
          opacity: 0;
          transition: all ease 0.5s;

          .revoke-btn {
            cursor: pointer;
            padding: 3px 15px;
            margin-left: 10px;
            border: 1px solid $danger;
            border-radius: 0.5rem;
            color: #fff;
            background-color: $danger;

            &:hover {
              background-color: #cc4d4e;
            }
          }
        }

        &:hover {
          .revoke-btn-box {
            width: auto;
            opacity: 1;
          }
        }
      }
    }
  }
</style>
