import axios from '@/http/axios'
import {createCancelTokenHandler} from "../axios/utils";

const cancelTokenHandlerObject = {
  getProfileClasses: createCancelTokenHandler('getProfileClasses')
}

function makeQuery (page = 1, filters = [], sorts = []) {
  let queries = []

  if (page > 1) queries.push(`page=${page}`)

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  return queries
}

/* Profile */
export function getProfile () {
  return axios({
    url: 'v1/admin/profile',
    method: 'get'
  })
}

export function getProfileClasses (page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/profile/classes${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getProfileClasses'].handleRequestCancellation().token
  })
}

export function editProfile (payment) {

  return axios.patch(`v1/admin/env/profile/${payment.id}`, payment)
}

export function getProfileActiveSessions () {
  return axios({
    url: 'v1/admin/profile/active-sessions',
    method: 'get'
  })
}

export function revokeActiveSession (id) {
  return axios({
    url: `v1/admin/profile/active-sessions/${id}`,
    method: 'delete'
  })
}
